import React from "react";
import "../components/all.min.css";


export default class HomePage extends React.Component {
  render() {
    var isletmeler = require("../../src/isletmeler.json");

    return (
      <div>
        <nav
          className="navbar navbar-expand-lg navbar-dark fixed-top"
          id="mainNav"
        >
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="#page-top" style={{fontFamily:"Nasa"}}>
              Basel
            </a>
            <button
              className="navbar-toggler navbar-toggler-right"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              Menü
              <i className="fas fa-bars"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav text-uppercase ml-auto">
                <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="#service">
                    E-Tahsilat
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="#contact">
                    İletişim
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <header className="masthead">
          <div className="container">
            <div className="intro-text">
              <div className="intro-lead-in">
                Sanal Pos Çözümleri
              </div>
              <div className="intro-heading text-uppercase" style={{fontFamily:"Nasa"}}>BASEL</div>
            </div>
          </div>
        </header>

        <section className="page-section" id="service">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading text-uppercase">E-TAHSİLAT</h2>
                <h3 className="section-subheading text-muted">
                  Ödeme işlemi gerçekleştireceğiniz işletmeyi seçiniz
                </h3>
              </div>
            </div>
            <div className="row text-center">
              {isletmeler.map(isletme => (
                <div className="col-md-4 mb-3">
                  <a
                    href={"/" + isletme.url}
                    target="_self"
                    style={{ color: isletme.renkKodu }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100"
                      height="100"
                      viewBox="0 0 24 24"
                      fill={isletme.renkKodu}
                    >
                      <path fill="none" d="M0 0h24v24H0V0z" />
                      <path d="M11.19 1.36l-7 3.11C3.47 4.79 3 5.51 3 6.3V11c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V6.3c0-.79-.47-1.51-1.19-1.83l-7-3.11c-.51-.23-1.11-.23-1.62 0zm-1.9 14.93L6.7 13.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l5.88-5.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-6.59 6.59c-.38.39-1.02.39-1.41 0z" />
                    </svg>
                    <h4 className="service-heading">{isletme.adi}</h4>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="page-section" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading text-uppercase">
                  İletişim ve Destek
                </h2>
                <h3 className="section-subheading text-muted">
                  Detaylı bilgi ve destek için bizimle iletişime geçin.
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <form
                  id="contactForm"
                  name="sentMessage"
                  noValidate="novalidate"
                >
                  <div className="row">
                    <div className="col-md-6 mt-1">
                      <div className="card mb-1">
                        <div className="card-header">TELEFON: 08505200699</div>
                      </div>
                      <div className="card mb-1">
                        <div className="card-header">
                          EMAİL : destek@basel.com.tr
                        </div>
                      </div>
                      <div className="card mb-1">
                        <div className="card-header">İSTANBUL ARGE OFİS ADRES</div>
                        <div className="card-body">
                          <p className="card-text">
                            Yıldız Teknik Üniversitesi Teknopark İkitelli OSB
                            Yıldız Teknopark binası kat:1 No:112
                            Başakşehir/İSTANBUL
                          </p>
                        </div>
                      </div>
                      <div className="card mb-1">
                        <div className="card-header">UŞAK OFİS ADRES</div>
                        <div className="card-body">
                          <p className="card-text">
                            Kurtuluş mh.Çavuş Çeşme Sok.Hasan IŞIK İş
                            MerkeziKat:1 No:8 Merkez/UŞAK
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt-1">
                      <div
                        className="container"
                        style={{
                          position: "relative",
                          paddingTop: "56.25%",
                          backgroundColor: "green"
                        }}
                      >
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.9893508347436!2d28.80735661568523!3d41.06922542362047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa5831f941819%3A0xca340723f0956b21!2sY%C4%B1ld%C4%B1z+Teknik+%C3%9Cniversitesi+%C4%B0kitelli+Teknopark!5e0!3m2!1str!2str!4v1560427978838!5m2!1str!2str"
                          title="Basel Adres Harita"
                          frameBorder="0"
                          allowFullScreen
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%"
                          }}
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <footer className="footer">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <span className="copyright">
                  Copyright &copy; <span style={{fontFamily:"Nasa"}} >Basel</span>Yazılım 2019
                </span>
              </div>
              <div className="col-md-6">
                <ul className="list-inline social-buttons">
                  <li className="list-inline-item">
                    <a href="https://www.instagram.com/baselyazilim/">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.facebook.com/baselyazilim">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>

        <div
          className="portfolio-modal modal fade"
          id="gecisModal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="close-modal" data-dismiss="modal">
                <div className="lr">
                  <div className="rl"></div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto">
                    <div className="modal-body">
                      <img
                        className="img-fluid d-block mx-auto"
                        src={require("../img/syy/gecisthun.jpg")}
                        alt=""
                      />
                      <p>
                        Spor salonları, güzellik merkezleri, dövüş sanatları
                        kulüpleri, SPA vb. gibi işletmelerde geçiş kontrol
                        sisteminin ana amacı güvenlikten çok para kaybını
                        engellemektir. Basel sizlere en kolay, en hızlı ve en
                        güvenilir Üye ve Müşteri Takibi deneyimini sunmak için
                        test edilmiş ve daima geliştirilmeye açık alternatif
                        çözümler sunar. Biometrik El Okuma donanımları, Iris
                        Okuma donanımları ve Parmak İzi Okuma donanımları ile
                        hem geçiş kontrol sisteminizi özelleştirebilir hem de ek
                        özelliklerinden fayda sağlayabilirsiniz. Basel Yazılım
                        Ekibi, daima güncellenen ve artan özellikleriyle çalışma
                        sisteminden ödün vermeyip işletmeniz için en uygun
                        alternatifleri ve çözümleri belirlemek üzere aima bir
                        telefon kadar yanınızda olacaktır.
                      </p>

                      <button
                        className="btn btn-primary"
                        data-dismiss="modal"
                        type="button"
                      >
                        <i className="fas fa-times"></i>
                        Kapat
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="portfolio-modal modal fade"
          id="turnikeModal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="close-modal" data-dismiss="modal">
                <div className="lr">
                  <div className="rl"></div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto">
                    <div className="modal-body">
                      <img
                        className="img-fluid d-block mx-auto"
                        src={require("../img/syy/turnikethun.jpg")}
                        alt=""
                      />
                      <p>
                        Artık bir spor merkezinin ayrılmaz parçası olan
                        Turnikeli Geçiş Sistemleri konusunda BASEL yıllarca
                        denenmiş, test edilmiş ürünler ile çalışır. Biz sektörün
                        lideri, referansları arasında İngiltere Olimpiyatları
                        bulunan ÖZAK TURNİKE ile çalışmaktayız.
                      </p>

                      <button
                        className="btn btn-primary"
                        data-dismiss="modal"
                        type="button"
                      >
                        <i className="fas fa-times"></i>
                        Kapat
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
