import React from "react";
import "../components/OnlineIslemler.css";
import "react-credit-cards/es/styles-compiled.css";
import Cards from "react-credit-cards";
import { Redirect, NavLink } from "react-router-dom";
var sha256 = require("js-sha256");
var { detect } = require('detect-browser');

export default class OnlineIslemler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Oturum: true,
      kullaniciAdi: "",
      UyeGuncelleMesage: "",      
      cvc: "",
      expiry: "",
      focus: "",
      name: "",
      number: "",
      selectNav: "",
      uye: "",
      mevcutpaket: [],
      borcluPaket: [],
      taksitliPaket: [],
      taksitler: [],
      paketler: [],
      paketAciklama:"",
      moka: "",
      sanalPosid:"",
      sanalPosTutar:0.00,
      sanalPosAciklama:"",
      
    };
    this.gcikis = this.gcikis.bind(this);
    this.bilgilerimiGetir = this.bilgilerimiGetir.bind(this);
    this.taksitleriGetir = this.taksitleriGetir.bind(this);
    this.paketIncele = this.paketIncele.bind(this);
    this.sanalPos = this.sanalPos.bind(this);
    this.handleSubmitUyeGuncelleModalForm = this.handleSubmitUyeGuncelleModalForm.bind(
      this
    );
    this.borcluPaketOde = this.borcluPaketOde.bind(this);
    
  }
  componentDidMount() {
    const storaKullanıcı = localStorage.getItem("kullaniciAdi");
    const storaUrl = localStorage.getItem("url");
    if (storaKullanıcı && storaUrl === this.props.isletmeURL) {
      this.setState({
        selectNav: "ozet",
        Oturum: true,
        kullaniciAdi: storaKullanıcı
      },()=>{this.bilgilerimiGetir()});
      
    } else {
      localStorage.removeItem("kullaniciAdi");
      localStorage.removeItem("url");
      this.setState({
        selectNav: "ozet",
        Oturum: false,
        kullaniciAdi: ""
      });
    }

    

   
    
  }

  taksitleriGetir(paket){
    const urlTaksitler= this.props.staticIp + "/api/Upi/Taksit?uyeno=" + this.state.uye.uyeNo+"&upirow="+paket.rowID;

    fetch(urlTaksitler)
      .then(res => res.json())
      .then(data => {
        this.setState(
          {
            taksitler: data.results
          });})


  }
  

  bilgilerimiGetir() {
    const urlkisisel =
      this.props.staticIp + "/api/UyeKarti?kadi=" + this.state.kullaniciAdi;
    const urlpaket =
      this.props.staticIp + "/api/Paket?kadi=" + this.state.kullaniciAdi;
    const urlmevcutpaket =
      this.props.staticIp + "/api/upi?kadi=" + this.state.kullaniciAdi;
    const urlmoka = this.props.staticIp + "/api/VirtualPos";

    fetch(urlkisisel)
      .then(res => res.json())
      .then(data => {
        this.setState(
          {
            uye: data.result
          },
          () => {
            fetch(urlpaket)
              .then(resp => resp.json())
              .then(datapaket => {
                this.setState(
                  {
                    paketler: datapaket.results
                  },
                  () => {
                    fetch(urlmevcutpaket)
                      .then(resp => resp.json())
                      .then(datamevcutpaket => {
                        this.setState(
                          {
                            mevcutpaket: datamevcutpaket.results
                          },
                          () => {
                            var borcinmevcut = this.state.mevcutpaket.filter(
                              paket => {
                                return (
                                  paket.kalanTutar !== "0" &&
                                  paket.taksit === false
                                );
                              }
                            );
                            this.setState({ borcluPaket: borcinmevcut });
                            var taksitinmevcut = this.state.mevcutpaket.filter(
                              paket => {
                                return (
                                  paket.kalanTutar !== "0" &&
                                  paket.taksit === true
                                );
                              }
                            );
                            this.setState({ taksitliPaket: taksitinmevcut });
                           
                           
                          }
                        );
                      });
                  }
                );
              });
          }
        );
      })
      .catch(console.log);
    fetch(urlmoka)
      .then(resp => resp.json())
      .then(datamoka => {
        this.setState({
          moka: datamoka.result
        });
      });
  }
  sanalPos(durum,data) {
    var sPid ="";
    var sPTutar=0.00;
    var sPAciklama ="";
    if(durum ==="borc"){
      sPid = "BOU"+this.state.uye.rowID+"P"+data.rowID+"R"+Math.floor(Math.random() * 10)+Math.floor(Math.random() * 10)+Math.floor(Math.random() * 10)+Math.floor(Math.random() * 10);
      sPTutar = parseFloat(data.kalanTutar.replace(",",".")).toFixed(2);
      sPAciklama= "BORÇ ÖDEME İŞLEMİ :"+ this.state.uye.adSoyad + "'IN  "+ data.paketAdi +" PAKETİNDEN KALAN "+ parseFloat(data.kalanTutar).toFixed(2) + " TL ÖDEME İŞLEMİDİR.";
    }
    else if(durum ==="taksit"){
      sPid = "TSU"+this.state.uye.rowID+"P"+data.paketRefRow+"T"+data.rowID+"R"+Math.floor(Math.random() * 10)+Math.floor(Math.random() * 10)+Math.floor(Math.random() * 10)+Math.floor(Math.random() * 10);
      sPTutar = parseFloat(data.taksitTutari.replace(",",".")).toFixed(2);
      sPAciklama= "TAKSİT ÖDEME İŞLEMİ :"+ this.state.uye.adSoyad + "'IN  "+ data.taksitAciklama ;
    }
    else if(durum ==="yenipaket"){
      var baslangic = new Date(); 
      var bitis = new Date();     
      if (data.paketSureTipi === "Gün"){
        bitis = new Date(bitis.setDate(bitis.getDate()+data.paketSuresi));
      }
      else if (data.paketSureTipi === "Hafta"){
        bitis = new Date(bitis.setDate(bitis.getDate()+data.paketSuresi*7));
      }
      else if (data.paketSureTipi === "Ay"){
        bitis = new Date(bitis.setMonth(bitis.getMonth()+data.paketSuresi));
      }
      else if (data.paketSureTipi === "Yıl") {
        bitis = new Date(bitis.setFullYear(bitis.getFullYear()+data.paketSuresi));
      }
      var baslangicStr =baslangic.getFullYear() +"-" +(baslangic.getMonth() + 1 < 10 ? "0" + (baslangic.getMonth() + 1) : baslangic.getMonth() + 1) + "-" +(baslangic.getDate() < 10 ? "0" + baslangic.getDate(): baslangic.getDate()) ;
      var bitisStr =bitis.getFullYear() +"-" +(bitis.getMonth() + 1 < 10 ? "0" + (bitis.getMonth() + 1) : bitis.getMonth() + 1) + "-" +(bitis.getDate() < 10 ? "0" + bitis.getDate(): bitis.getDate()) ;
      
      sPid = "YPU"+this.state.uye.rowID+"P"+data.rowID+"S"+baslangicStr+"E"+bitisStr+"R"+Math.floor(Math.random() * 10)+Math.floor(Math.random() * 10)+Math.floor(Math.random() * 10)+Math.floor(Math.random() * 10);
      sPTutar = parseFloat(data.paketTutari.replace(",",".")).toFixed(2);
      sPAciklama= "YENİ PAKET ALMA :"+ this.state.uye.adSoyad + "'IN "+ data.paketAdi + " PAKETİNİ ALMA İŞLEMİ " ;
    }
    this.setState({
      sanalPosid:sPid,
      sanalPosTutar:sPTutar,
      sanalPosAciklama:sPAciklama,

    },()=>{window.$("#SanalPosModal").modal("show")});
    
  }

  borcluPaketOde(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
   
    const mkurl =this.props.sanalPosUrl;
    

      const mokadata = {
        PaymentDealerAuthentication: {
          DealerCode: this.state.moka.dealerCode,
          Username: this.state.moka.apiUserName,
          Password: this.state.moka.apiPass,
          CheckKey: sha256(
            this.state.moka.dealerCode +
              "MK" +
              this.state.moka.apiUserName +
              "PD" +
              this.state.moka.apiPass
          )
        },
        PaymentDealerRequest: {
          CardHolderFullName: formData.get("name"),
          CardNumber: formData.get("number"),
          ExpMonth: formData.get("expiry").slice(0, 2),
          ExpYear: "20"+formData.get("expiry").slice(2, 4),
          CvcNumber: formData.get("cvc"),
          Amount: this.state.sanalPosTutar,
          Currency: "TL",
          InstallmentNumber: "1",
          ClientIP: "192.168.1.1",
          OtherTrxCode: this.state.sanalPosid,
          IsPreAuth: 0,
          IsPoolPayment: 0,
          IntegratorId: 3,
          Software: detect().name + "-" +detect().version + "-" +detect().os,
          RedirectUrl:
          this.props.staticIp+"/CheckOut/PayResult?id="+this.state.sanalPosid,
          RedirectType: 1,
          Description: this.state.sanalPosAciklama,
          BuyerInformation: {
            BuyerFullName: this.state.uye.adSoyad,
            BuyerEmail: this.state.uye.mail1,
            BuyerGsmNumber: this.state.uye.tel1,
            BuyerAddress: this.state.uye.adres1
          }
        }
      };

    
    
    fetch(mkurl, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(mokadata),
      credentials: 'omit',
    }).then(res=>res.json())
      .then(res => {
        if (res.ResultCode === "Success") {
             window.open(res.Data, "_parent");
             }

      });

    
  }
  paketIncele(paket){
    this.setState(
      {
        paketAciklama: paket
      },
      () => {window.$("#paketAciklama").modal("show")})

  }

  

  handleInputFocus = e => {
    this.setState({ focus: e.target.name });
  };

  handleInputChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };
  
  
  gcikis() {
    localStorage.removeItem("kullaniciAdi");
    localStorage.removeItem("url");
    this.setState(
      {
        Oturum: false,
        kullaniciAdi: "",
        UyeGuncelleMesage: ""
      });
  }
  handleSubmitUyeGuncelleModalForm(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const todayDate = new Date();
    const todayYear = todayDate.getFullYear();
    const todayMonth = todayDate.getMonth();
    const todayDay = todayDate.getDate();
    const birthDate = new Date(formData.get("dogumTarihi"));
    const birthYear = birthDate.getFullYear();
    const birthMonth = birthDate.getMonth();
    const birthDay = birthDate.getDate();
    var age = todayYear - birthYear;

    if (todayMonth < birthMonth - 1) {
      age--;
    }

    if (birthMonth - 1 === todayMonth && todayDay < birthDay) {
      age--;
    }

    const url = this.props.staticIp + "/api/UyeKarti";
    const jsondata = {
      rowID: formData.get("rowID"),
      uyeAdi: formData.get("uyeAdi"),
      uyeSoyadi: formData.get("uyeSoyadi"),
      tcNo: formData.get("tcKimlikNo"),
      cinsiyet: formData.get("cinsiyet"),
      mDurum: formData.get("medeniDurum"),
      dTarihi: formData.get("dogumTarihi"),
      tel1: formData.get("telefon"),
      tel2: "",
      mail1: formData.get("mail"),
      mail2: "",
      adres1: formData.get("adres"),
      adres2: "",
      resim: null,
      acilTel: "",
      acilNot: "",
      kanGrubu: "",
      aile1: false,
      yas: age,
      meslek: formData.get("meslek"),
      kayitYeri: 2,
      ordinateur: "ONLİNE İŞLEMLER",
      ram: "8",
      sistem: detect().name + "-" +detect().version + "-" +detect().os,
      kullaniciAdi: formData.get("kullaniciAdi"),
      sifre: formData.get("sifre")
    };
    console.log(jsondata);

    fetch(url, {
      method: "PUT", // or 'PUT'
      body: JSON.stringify(jsondata), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json; charset=utf8"
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.status) {
          window.$("#UyeGuncelleModal").modal("hide");
          this.bilgilerimiGetir();
        } else {
          this.setState({
            UyeGuncelleMesage: "Hata"
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          YeniUyeMesage:
            "Veritabanına erişilemiyor lütfen daha sonra tekrar deneyiniz! Hata Mesajı: " +
            err
        });
      });
  }
  render() {
    if (this.state.Oturum === false) {
      return <Redirect to={"/"+this.props.isletmeURL} />;
    }
    return (
      <div>
  
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
          <NavLink
            className="navbar-brand  mr-2 ml-2"
            to="/"
            style={{ color: "#fed136",fontFamily:"Nasa" }}
          >
           Basel
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarHor"
            aria-controls="navbarHor"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarHor">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
              <li className="nav-item">
                <button
                  className={
                    this.state.selectNav === "ozet"
                      ? "btn btn-link nav-link active"
                      : "btn btn-link nav-link"
                  }
                  onClick={() => {
                    this.setState({ selectNav: "ozet" });
                  }}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <i className="fa fa-bullhorn" aria-hidden="true"></i> Özet
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    this.state.selectNav === "bilgi"
                      ? "btn btn-link nav-link active"
                      : "btn btn-link nav-link"
                  }
                  onClick={() => {
                    this.setState({ selectNav: "bilgi" });
                  }}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <i className="fa fa-user" aria-hidden="true"></i> Bilgilerim
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    this.state.selectNav === "paket"
                      ? "btn btn-link nav-link active"
                      : "btn btn-link nav-link"
                  }
                  onClick={() => {
                    this.setState({ selectNav: "paket" });
                  }}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <i className="fa fa-cubes" aria-hidden="true"></i> Paketlerim
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    this.state.selectNav === "paketal"
                      ? "btn btn-link nav-link active"
                      : "btn btn-link nav-link"
                  }
                  onClick={() => {
                    this.setState({ selectNav: "paketal" });
                  }}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                  Paket Satın Al
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  onClick={this.gcikis}
                >
                  <i className="fa fa-times" aria-hidden="true"></i> Güvenli
                  Çıkış
                </button>
              </li>
            </ul>
          </div>
        </nav>

        <div className="container-fluid">
          <div className="row">
            <nav className="col-md-2 d-none d-md-block bg-light sidebar">
              <div className="sidebar-sticky">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <button
                      className={
                        this.state.selectNav === "ozet"
                          ? "btn btn-link nav-link active"
                          : "btn btn-link nav-link"
                      }
                      onClick={() => {
                        this.setState({ selectNav: "ozet" });
                      }}
                    >
                      <i className="fa fa-bullhorn" aria-hidden="true"></i> Özet
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={
                        this.state.selectNav === "bilgi"
                          ? "btn btn-link nav-link active"
                          : "btn btn-link nav-link"
                      }
                      onClick={() => {
                        this.setState({ selectNav: "bilgi" });
                      }}
                    >
                      <i className="fa fa-user" aria-hidden="true"></i>{" "}
                      Bilgilerim
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={
                        this.state.selectNav === "paket"
                          ? "btn btn-link nav-link active"
                          : "btn btn-link nav-link"
                      }
                      onClick={() => {
                        this.setState({ selectNav: "paket" });
                      }}
                    >
                      <i className="fa fa-cubes" aria-hidden="true"></i>{" "}
                      Paketlerim
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={
                        this.state.selectNav === "paketal"
                          ? "btn btn-link nav-link active"
                          : "btn btn-link nav-link"
                      }
                      onClick={() => {
                        this.setState({ selectNav: "paketal" });
                      }}
                    >
                      <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                      Paket Satın Al
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="btn btn-link nav-link"
                      onClick={this.gcikis}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i> Güvenli
                      Çıkış
                    </button>
                  </li>
                </ul>
              </div>
            </nav>

            {this.state.selectNav === "ozet" ? (
              <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                  <h1 className="h2">Özet</h1>
                </div>
                <div className="container">
                  <h3>Hoşgeldin, {this.state.uye.kullaniciAdi}</h3>
                </div>
              </main>
            ) : this.state.selectNav === "bilgi" ? (
              <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                  <h1 className="h2">Bilgilerim</h1>
                  <div className="btn-toolbar mb-2 mb-md-0">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-success"
                      href="#UyeGuncelleModal"
                      data-toggle="modal"
                      data-target="#UyeGuncelleModal"
                    >
                      Bilgileri Güncelle
                    </button>
                  </div>
                </div>
                <div className="container">
                  <table className="table table-sm">
                    <tbody>
                      <tr>
                        <th scope="row">Kullanıcı Adı</th>
                        <td>{this.state.uye.kullaniciAdi}</td>
                      </tr>

                      <tr>
                        <th scope="row">Adı</th>
                        <td>{this.state.uye.uyeAdi}</td>
                      </tr>

                      <tr>
                        <th scope="row">Soyadı</th>
                        <td>{this.state.uye.uyeSoyadi}</td>
                      </tr>
                      <tr>
                        <th scope="row">Tc Kimlik No</th>
                        <td>{this.state.uye.tcNo}</td>
                      </tr>
                      <tr>
                        <th scope="row">Cinsiyet</th>
                        <td>{this.state.uye.cinsiyet}</td>
                      </tr>
                      <tr>
                        <th scope="row">Doğum Tarihi</th>
                        <td>
                          {new Date(this.state.uye.dTarihi).getFullYear() +
                            "-" +
                            (new Date(this.state.uye.dTarihi).getMonth() + 1 <
                            10
                              ? "0" +
                                (new Date(this.state.uye.dTarihi).getMonth() +
                                  1)
                              : new Date(this.state.uye.dTarihi).getMonth() +
                                1) +
                            "-" +
                            (new Date(this.state.uye.dTarihi).getDate() < 10
                              ? "0" + new Date(this.state.uye.dTarihi).getDate()
                              : new Date(this.state.uye.dTarihi).getDate())}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Medeni Durum</th>
                        <td>{this.state.uye.mDurum}</td>
                      </tr>
                      <tr>
                        <th scope="row">Meslek</th>
                        <td>{this.state.uye.meslek}</td>
                      </tr>

                      <tr>
                        <th scope="row">E-Posta</th>
                        <td>{this.state.uye.mail1}</td>
                      </tr>
                      <tr>
                        <th scope="row">Telefon</th>
                        <td>{this.state.uye.tel1}</td>
                      </tr>
                      <tr>
                        <th scope="row">Adres</th>
                        <td>{this.state.uye.adres1}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </main>
            ) : this.state.selectNav === "paket" ? (
              <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                  <h1 className="h2">Paket Bilgilerim</h1>
                </div>
                <div className="container">
                  <p className="lead">Mevcut Paketlerim</p>
                  {this.state.mevcutpaket === [] ? (
                    <p className="lead">Paket bilginiz bulunmamaktadır.</p>
                  ) : (
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th scope="col">Paket Kodu</th>
                          <th scope="col">Paket Adı</th>
                          <th scope="col">Başlangıç Tarihi</th>
                          <th scope="col">Bitiş Tarihi</th>
                          <th scope="col">Kalan Kontor</th>
                          <th scope="col">Borç Miktarı</th>
                          <th scope="col">Ödeme İşlemi</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.mevcutpaket.map(paket => {
                          return (
                            <tr key={paket.rowID}>
                              <td>{paket.paketKodu}</td>
                              <td>{paket.paketAdi}</td>
                              <td>{paket.balsangicTarihi}</td>
                              <td>{paket.bitisTarihi}</td>
                              <td>{paket.paketSayacDurum}</td>
                              <td>{paket.kalanTutar}</td>
                              <td>{paket.kalanTutar !== "0" && paket.taksit === false ?
                              <button
                                    type="button"
                                    className="btn btn-warning btn-sm"
                                    onClick={() => {
                                      this.sanalPos("borc",paket);
                                    }}
                                  >
                                    Öde
                                  </button>:
                                  paket.kalanTutar !== "0" && paket.taksit === true?
                                  <button
                                    type="button"
                                    className="btn btn-info btn-sm"
                                    onClick={() => {
                                      this.taksitleriGetir(paket);
                                    }}                                    
                                  >
                                    Taksitleri Getir
                                  </button>:
                                  null
                                }</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  
                  {this.state.taksitler.length > 0 ? (
                    <div>
                      <p className="lead">Taksitler</p>
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th scope="col">Taksit Açıklaması</th>
                            <th scope="col">Taksit Tarihi</th>
                            <th scope="col">Taksit Tutarı</th>
                            <th scope="col">Hemen Öde</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.taksitler.map(taksit => {
                            return (
                              <tr key={taksit.rowID}>
                                <td>{taksit.taksitAciklama}</td>
                                <td>{taksit.taksitTarihi}</td>
                                <td>{taksit.taksitTutari}</td>                                
                                <td>
                                {taksit.taksitTutari !== "0"?
                                <button
                                    type="button"
                                    className="btn btn-warning btn-sm"
                                    onClick={() => {
                                      this.sanalPos("taksit",taksit);
                                    }}
                                  >
                                    Öde
                                  </button>
                                  :
                                  null}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
              </main>
            ) : (
              <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                  <h1 className="h2">Paket Satın Al</h1>
                </div>
                <div className="container">
                  {this.state.borcluPaket.length > 0 ||
                  this.state.taksitliPaket.length > 0 ? (
                    <div className="alert alert-warning" role="alert">
                      Uyarı: Yeni paket alabilmek için önce borçlu paketlerin
                      ödemesini gerçekleştirin !
                    </div>
                  ) : null}
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Paket Kodu</th>
                        <th scope="col">Paket Adı</th>
                        <th scope="col">Paket Süresi</th>
                        <th scope="col">Paket Tutarı</th>
                        <th scope="col">İncele</th>
                        <th scope="col">Hemen Al</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.paketler.map(paket => {
                        return (
                          <tr key={paket.rowID}>
                            <td>{paket.paketKodu}</td>
                            <td>{paket.paketAdi}</td>
                            <td>
                              {paket.paketSuresi} {paket.paketSureTipi}{" "}
                            </td>
                            <td>{paket.paketTutari}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-info btn-sm"
                                onClick={() => {
                                  this.paketIncele(paket);
                                }}

                                disabled={
                                  this.state.paketAciklama === null 
                                    ? true
                                    : false
                                }
                              >
                                İncele
                              </button>
                            </td>
                            

                            <td>
                              <button
                                type="button"
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                  this.sanalPos("yenipaket",paket);
                                }}

                                disabled={
                                  this.state.borcluPaket.length > 0 ||
                                  this.state.taksitliPaket.length > 0
                                    ? true
                                    : false
                                }
                              >
                                Hemen Al
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </main>
            )}
          </div>
        </div>

        <div
          className="modal fade"
          id="UyeGuncelleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <form onSubmit={this.handleSubmitUyeGuncelleModalForm}>
              <div className="modal-content">
                <div className="modal-header text-center">
                  <h4 className="modal-title w-100 font-weight-bold">
                    Bilgilerimi Güncelle
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body mx-3">
                  <div className="form-group">
                    <input
                      type="hidden"
                      className="form-control"
                      id="rowID"
                      name="rowID"
                      placeholder="rowID"
                      defaultValue={this.state.uye.rowID}
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="kullaniciAdi">Kullanıcı Adı</label>
                      <input
                        type="text"
                        className="form-control"
                        id="kullaniciAdi"
                        name="kullaniciAdi"
                        defaultValue={this.state.uye.kullaniciAdi}
                        readOnly={true}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="sifre">Şifre</label>
                      <input
                        type="password"
                        className="form-control"
                        id="sifre"
                        name="sifre"
                        placeholder="Şifre"
                        defaultValue={this.state.uye.sifre}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="tcKimlikNo">TC Kimlik No</label>
                    <input
                      type="text"
                      className="form-control"
                      id="tcKimlikNo"
                      name="tcKimlikNo"
                      placeholder="Tc Kimlik No"
                      defaultValue={this.state.uye.tcNo}
                      readOnly={true}
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="uyeAdi">Üye Adı</label>
                      <input
                        type="text"
                        className="form-control"
                        id="uyeAdi"
                        name="uyeAdi"
                        placeholder="Üye Adı"
                        defaultValue={this.state.uye.uyeAdi}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="uyeSoyadi">Üye Soyadı</label>
                      <input
                        type="text"
                        className="form-control"
                        id="uyeSoyadi"
                        name="uyeSoyadi"
                        placeholder="Üye Soyadı"
                        defaultValue={this.state.uye.uyeSoyadi}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="cinsiyet">Cinsiyet</label>
                      <select
                        id="cinsiyet"
                        className="form-control"
                        name="cinsiyet"
                        defaultValue={this.state.uye.cinsiyet}
                        required
                      >
                        <option
                          
                        >
                          Erkek
                        </option>
                        <option
                         
                        >
                          Bayan
                        </option>
                        <option
                          
                        >
                          Çocuk
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="medeniDurum">Medeni Durum</label>
                      <select
                        id="medeniDurum"
                        className="form-control"
                        name="medeniDurum"
                        defaultValue={this.state.uye.mDurum}
                        required
                      >
                        <option
                          
                        >
                          Bekar
                        </option>
                        <option
                          
                        >
                          Evli
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="dogumTarihi">Doğum Tarihi</label>
                      <input
                        type="date"
                        className="form-control"
                        id="dogumTarihi"
                        name="dogumTarihi"
                        placeholder="Doğum Tarihi"
                        defaultValue={
                          new Date(this.state.uye.dTarihi).getFullYear() +
                          "-" +
                          (new Date(this.state.uye.dTarihi).getMonth() + 1 < 10
                            ? "0" +
                              (new Date(this.state.uye.dTarihi).getMonth() + 1)
                            : new Date(this.state.uye.dTarihi).getMonth() + 1) +
                          "-" +
                          (new Date(this.state.uye.dTarihi).getDate() < 10
                            ? "0" + new Date(this.state.uye.dTarihi).getDate()
                            : new Date(this.state.uye.dTarihi).getDate())
                        }
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="meslek">Meslek</label>
                      <select
                        id="meslek"
                        className="form-control"
                        name="meslek"
                        defaultValue={this.state.uye.meslek}
                        required
                      >
                        <option>
                          Mühendis
                        </option>
                        <option>
                          Doktor
                        </option>
                        <option>
                          Avukat
                        </option>
                        <option>
                          Hakim
                        </option>
                        <option>
                          Öğretmen
                        </option>
                        <option>
                          Eczacı
                        </option>
                        <option>
                          Diş Doktoru
                        </option>
                        <option>
                          Bankacı
                        </option>
                        <option>
                          Terzi
                        </option>
                        <option>
                          Polis
                        </option>
                        <option>
                          Sigortacı
                        </option>
                        <option>
                          Aşçı
                        </option>
                        <option>
                          Aşçıbaşı
                        </option>
                        <option>
                          Aile Hekimi
                        </option>
                        <option>
                          Arkeolog
                        </option>
                        <option>
                          Borsacı
                        </option>
                        <option>
                          Cerrah
                        </option>
                        <option>
                          Tercüman
                        </option>
                        <option>
                          Dalgıç
                        </option>
                        <option>
                          Diyetist
                        </option>
                        <option>
                          Emlakçı
                        </option>
                        <option>
                          Ev Hanımı
                        </option>
                        <option>
                          Fotoğrafcı
                        </option>
                        <option>
                          Futbolcu
                        </option>
                        <option>
                          Gazeteci
                        </option>
                        <option>
                          Hakem
                        </option>
                        <option>
                          Hemşire
                        </option>
                        <option>
                          Mimar
                        </option>
                        <option>
                          Veteriner
                        </option>
                        <option>
                          Laborant
                        </option>
                        <option>
                          Memur
                        </option>
                        <option>
                          Öğrenci
                        </option>
                        <option>
                          Yazar
                        </option>
                        <option>
                          Ressam
                        </option>
                        <option>
                          Muhasebeci
                        </option>
                        <option>
                          Psikolog
                        </option>
                        <option>
                          Psikiyatris
                        </option>
                        <option>
                          Esnaf
                        </option>
                        <option>
                          Bilişimci
                        </option>
                        <option>
                          Öğretim Görevlisi
                        </option>
                        <option>
                          Askeri Personel
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="telefon">Cep Telefon</label>
                      <input
                        type="phone"
                        className="form-control"
                        id="telefon"
                        name="telefon"
                        placeholder="Telefon"
                        defaultValue={this.state.uye.tel1}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="mail">E-Posta</label>
                      <input
                        type="mail"
                        className="form-control"
                        id="mail"
                        name="mail"
                        placeholder="E-Posta"
                        defaultValue={this.state.uye.mail1}
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="adres">Addres</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="adres"
                      name="adres"
                      placeholder="Adres"
                      defaultValue={this.state.uye.adres1}
                      required
                    />
                  </div>

                  {this.state.UyeGuncelleMesage ? (
                    <div
                      className="alert alert-warning alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>Hata!</strong> {this.state.UyeGuncelleMesage}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={e => {
                          this.setState({ UyeGuncelleMesage: "" });
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className="modal-footer d-flex justify-content-center">
                  <button className="btn btn-success" type="submit">
                    Bilgileri Güncelle
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className="modal fade"
          id="SanalPosModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl bg-info" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  Sanal POS
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <form onSubmit={this.borcluPaketOde}>
                  
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <div className="form-group">
                        <label htmlFor="cekilecekTutar">Çekilecek Tutar</label>
                        <input
                          id="cekilecekTutar"
                          className="form-control"
                          type="text"
                          name="cekilecekTutar"
                          value ={this.state.sanalPosTutar}
                          
                          required
                          readOnly={true}                            
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="cardnumber">Kredi Kartı Numarası</label>
                        <input
                          id="cardnumber"
                          className="form-control"
                          type="tel"
                          name="number"
                          placeholder="Kredi Kartı Numarası"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="adsoyad">Kredi Kartı Üzerindeki İsim</label>
                        <input
                          id="adsoyad"
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder="Kredi Kartı Üzerindeki İsim"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="expiry">Son Kullanma Tarihi</label>
                        <input
                          id="expiry"
                          className="form-control"
                          type="text"
                          name="expiry"
                          placeholder="AAYY"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          maxLength="4"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="cvc">CVC</label>
                        <input
                          id="cvc"
                          className="form-control"
                          type="tel"
                          name="cvc"
                          placeholder="CVC"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          maxLength="3"
                          required
                        />
                      </div>
                    </div>
                    <div id="PaymentForm" className="form-group col-md-6">
                      <Cards
                        cvc={this.state.cvc}
                        expiry={this.state.expiry}
                        focus={this.state.focus}
                        name={this.state.name}
                        number={this.state.number}
                      />
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    3D Ödeme Yap
                  </button>
                </form>
    
              </div>
              </div>
          </div>
        </div>
       
        <div className="modal fade" id="paketAciklama" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">{this.state.paketAciklama.paketKodu} - {this.state.paketAciklama.paketAdi} </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="lead">{this.state.paketAciklama.paketAciklama}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Kapat</button>
                
              </div>
            </div>
          </div>
        </div>
              
      
      
      </div>
    );
  }
}
