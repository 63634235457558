import React from "react";
import { Redirect } from "react-router-dom";
import "../components/giris.css";

export default class GirisPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Oturum: false,
      kullaniciAdi: "",
      sifre: "",
      OturumMesage: "",
      YeniUyeMesage: ""
    };
    this.login = this.login.bind(this);
    this.handleSubmitOturumForm = this.handleSubmitOturumForm.bind(this);
    this.handleSubmitYeniUyeForm = this.handleSubmitYeniUyeForm.bind(this);
  }
  componentDidMount() {
    const storaKullanıcı = localStorage.getItem("kullaniciAdi");
    const storaUrl = localStorage.getItem("url");
    
    if (storaKullanıcı && storaUrl === this.props.isletmeURL) {
      this.setState({
        Oturum: true,
        kullaniciAdi: storaKullanıcı
      });
    } else {
      localStorage.removeItem("kullaniciAdi");
      localStorage.removeItem("url");
      this.setState({
        Oturum: false,
        kullaniciAdi: ""
      });
    }
  }
  login(asd) {
    localStorage.setItem("kullaniciAdi", asd);
    localStorage.setItem("url", this.props.isletmeURL);
    this.setState({
      Oturum: true,
      kullaniciAdi: asd
    },()=>{this.renderRedirect()});
  }
  handleSubmitOturumForm(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const url =
      this.props.staticIp +
      "/api/UyeKarti/login?kadi=" +
      formData.get("kullaniciAdi") +
      "&sifre=" +
      formData.get("sifre");
    fetch(url)
      .then(res => res.json())
      .then(data => {
        if (data.status) {
          window.$("#OturumModal").modal("hide");
          this.login(formData.get("kullaniciAdi"));
        } else {
          this.setState({
            OturumMesage: data.message
          });
        }
      })
      .catch(err => {
        this.setState({
          OturumMesage:
            "Veritabanına erişilemiyor lütfen daha sonra tekrar deneyiniz! Hata Mesajı: " +
            err
        });
      });
  }
  handleSubmitYeniUyeForm(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const todayDate = new Date();
    const todayYear = todayDate.getFullYear();
    const todayMonth = todayDate.getMonth();
    const todayDay = todayDate.getDate();
    const birthDate = new Date(formData.get("dogumTarihi"));
    const birthYear = birthDate.getFullYear();
    const birthMonth = birthDate.getMonth();
    const birthDay = birthDate.getDate();
    var age = todayYear - birthYear;

    if (todayMonth < birthMonth - 1) {
      age--;
    }

    if (birthMonth - 1 === todayMonth && todayDay < birthDay) {
      age--;
    }

    const url = this.props.staticIp + "/api/UyeKarti";
    const jsondata = {
      uyeAdi: formData.get("uyeAdi"),
      uyeSoyadi: formData.get("uyeSoyadi"),
      tcNo: formData.get("tcKimlikNo"),
      cinsiyet: formData.get("cinsiyet"),
      mDurum: formData.get("medeniDurum"),
      dTarihi: formData.get("dogumTarihi"),
      tel1: formData.get("telefon"),
      tel2: "",
      mail1: formData.get("mail"),
      mail2: "",
      adres1: formData.get("adres"),
      adres2: "",
      resim: null,
      acilTel: "",
      acilNot: "",
      kanGrubu: "",
      aile1: false,
      yas: age,
      meslek: formData.get("meslek"),
      kayitYeri: 2,
      ordinateur: "ONLİNE İŞLEMLER",
      ram: "8",
      sistem: "Browser Bilgisi",
      kullaniciAdi: formData.get("kullaniciAdi"),
      sifre: formData.get("sifre")
    };
    

    fetch(url, {
      method: "POST", // or 'PUT'
      body: JSON.stringify(jsondata), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json; charset=utf8"
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.status) {
          window.$("#YeniUyeModal").modal("hide");
          this.login(formData.get("kullaniciAdi")
          
          );
        } else {
          this.setState({
            YeniUyeMesage: data.message
          });
        }
      })
      .catch(err => {
        
        this.setState({
          YeniUyeMesage:
            "Veritabanına erişilemiyor lütfen daha sonra tekrar deneyiniz! Hata Mesajı: " +
            err
        });
      });
  }
  tcdogrulama = e => {
    var TCNO = e.target.value;

    var tek = 0,
      cift = 0,
      sonuc = 0,
      TCToplam = 0,
      i = 0,
      hatali = [
        11111111110,
        22222222220,
        33333333330,
        44444444440,
        55555555550,
        66666666660,
        7777777770,
        88888888880,
        99999999990
      ];

    tek =
      parseInt(TCNO[0]) +
      parseInt(TCNO[2]) +
      parseInt(TCNO[4]) +
      parseInt(TCNO[6]) +
      parseInt(TCNO[8]);
    cift =
      parseInt(TCNO[1]) +
      parseInt(TCNO[3]) +
      parseInt(TCNO[5]) +
      parseInt(TCNO[7]);

    tek = tek * 7;
    sonuc = Math.abs(tek - cift);
    for (i = 0; i < 10; i++) {
      TCToplam += parseInt(TCNO[i]);
    }
    
   
    if (
      hatali.toString().indexOf(TCNO) !== -1 ||
      TCToplam % 10 !== Number(TCNO[10]) ||
      sonuc % 10 !== Number(TCNO[9]) ||
      TCNO.length !== 11 ||
      isNaN(TCNO) ||
      TCNO[0] === 0
    ) {
      
      window.$("#tcKimlikNo").removeClass("is-valid");
      window.$("#tcKimlikNo").addClass("is-invalid");
    } else {
      window.$("#tcKimlikNo").removeClass("is-invalid");
      window.$("#tcKimlikNo").addClass("is-valid");
    }
  }
  renderRedirect = () => {
    if (this.state.Oturum) {
      return <Redirect to={"/" + this.props.isletmeURL + "_onlineislemler"} />
    }
  }
  render() {
    
      return (
        <div className="text-center girisPageBody">
          {this.renderRedirect()}
          <form className="form-signin" onSubmit={this.handleSubmitOturumForm}>
            <h1 className="h2 mb-3 font-weight-normal">
              {this.props.isletmeAdi}
            </h1>
            <h1 className="h3 mb-3 font-weight-normal">Lütfen Giriş Yapınız</h1>
            <label for="kullaniciAdi" className="sr-only">
              Kullanıcı Adı
            </label>
            <input
              type="text"
              id="kullaniciAdi"
              name="kullaniciAdi"
              className="form-control"
              placeholder="Kullanıcı Adı"
              required
              autofocus=""
            />
            <label for="sifre" className="sr-only">
              Şifre
            </label>
            <input
              type="password"
              id="sifre"
              name="sifre"
              className="form-control"
              placeholder="Şifre"
              required
            />

            <button className="btn btn-lg btn-primary btn-block" type="submit">
              Giriş Yap
            </button>
            {this.props.uyelikDurumu ?
            <button
            className="btn btn-sm btn-light btn-block"
            data-toggle="modal"
            data-target="#YeniUyeModal"
          >
            Üye Ol
          </button>
          :
          null
            
          
          }
            
            {this.state.OturumMesage ? (
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                <strong>Hata!</strong> {this.state.OturumMesage}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={e => {
                    this.setState({ OturumMesage: "" });
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : null}
            <p className="mt-5 mb-3 text-muted">
              {this.props.isletmeAdi} © 2019
            </p>
          </form>

          <div
            className="modal fade"
            id="YeniUyeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <form onSubmit={this.handleSubmitYeniUyeForm}>
                <div className="modal-content">
                  <div className="modal-header text-center">
                    <h4 className="modal-title w-100 font-weight-bold">
                      Üye Ol
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body mx-3">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="kullaniciAdi">Kullanıcı Adı</label>
                        <input
                          type="text"
                          className="form-control"
                          id="kullaniciAdiYeniUye"
                          name="kullaniciAdi"
                          placeholder="Kullanıcı Adı"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="sifre">Şifre</label>
                        <input
                          type="password"
                          className="form-control"
                          id="sifreYeniUye"
                          name="sifre"
                          placeholder="Şifre"
                          required
                          minLength="8"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="tcKimlikNo">TC Kimlik No</label>
                      <input
                        type="text"
                        className="form-control"
                        id="tcKimlikNo"
                        name="tcKimlikNo"
                        placeholder="Tc Kimlik No"
                        onChange={this.tcdogrulama}
                        maxLength="11"
                        required
                      />
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="uyeAdi">Üye Adı</label>
                        <input
                          type="text"
                          className="form-control"
                          id="uyeAdi"
                          name="uyeAdi"
                          placeholder="Üye Adı"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="uyeSoyadi">Üye Soyadı</label>
                        <input
                          type="text"
                          className="form-control"
                          id="uyeSoyadi"
                          name="uyeSoyadi"
                          placeholder="Üye Soyadı"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="cinsiyet">Cinsiyet</label>
                        <select
                          id="cinsiyet"
                          className="form-control"
                          name="cinsiyet"
                          defaultValue="Erkek"
                          required
                        >
                          <option>Erkek</option>
                          <option>Bayan</option>
                          <option>Çocuk</option>
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="medeniDurum">Medeni Durum</label>
                        <select
                          id="medeniDurum"
                          className="form-control"
                          name="medeniDurum"
                          defaultValue="Bekar"
                          required
                        >
                          <option>Bekar</option>
                          <option>Evli</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="dogumTarihi">Doğum Tarihi</label>
                        <input
                          type="date"
                          className="form-control"
                          id="dogumTarihi"
                          name="dogumTarihi"
                          placeholder="Doğum Tarihi"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="meslek">Meslek</label>
                        <select
                          id="meslek"
                          className="form-control"
                          name="meslek"
                          defaultValue="Mühendis"
                          required
                        >
                          <option>Mühendis</option>
                          <option>Doktor</option>
                          <option>Avukat</option>
                          <option>Hakim</option>
                          <option>Öğretmen</option>
                          <option>Eczacı</option>
                          <option>Diş Doktoru</option>
                          <option>Bankacı</option>
                          <option>Terzi</option>
                          <option>Polis</option>
                          <option>Sigortacı</option>
                          <option>Aşçı</option>
                          <option>Aşçıbaşı</option>
                          <option>Aile Hekimi</option>
                          <option>Arkeolog</option>
                          <option>Borsacı</option>
                          <option>Cerrah</option>
                          <option>Tercüman</option>
                          <option>Dalgıç</option>
                          <option>Diyetist</option>
                          <option>Emlakçı</option>
                          <option>Ev Hanımı</option>
                          <option>Fotoğrafcı</option>
                          <option>Futbolcu</option>
                          <option>Gazeteci</option>
                          <option>Hakem</option>
                          <option>Hemşire</option>
                          <option>Mimar</option>
                          <option>Veteriner</option>
                          <option>Laborant</option>
                          <option>Memur</option>
                          <option>Öğrenci</option>
                          <option>Yazar</option>
                          <option>Ressam</option>
                          <option>Muhasebeci</option>
                          <option>Psikolog</option>
                          <option>Psikiyatris</option>
                          <option>Esnaf</option>
                          <option>Bilişimci</option>
                          <option>Öğretim Görevlisi</option>
                          <option>Askeri Personel</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="telefon">Cep Telefon</label>
                        <input
                          type="phone"
                          className="form-control"
                          id="telefon"
                          name="telefon"
                          placeholder="Telefon"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="mail">E-Posta</label>
                        <input
                          type="mail"
                          className="form-control"
                          id="mail"
                          name="mail"
                          placeholder="E-Posta"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="adres">Addres</label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="adres"
                        name="adres"
                        placeholder="Adres"
                        required
                      />
                    </div>

                    {this.state.YeniUyeMesage ? (
                      <div
                        className="alert alert-warning alert-dismissible fade show"
                        role="alert"
                      >
                        <strong>Hata!</strong> {this.state.YeniUyeMesage}
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                          onClick={e => {
                            this.setState({ YeniUyeMesage: "" });
                          }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="modal-footer d-flex justify-content-center">
                    <button className="btn btn-success" type="submit">
                      Üye Ol
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }

