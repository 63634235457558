import React from "react";
import HomePage from "./HomePage.js";
import GirisPage from "./GirisPage.js";
import OnlineIslemler from "./OnlineIslemler.js";
import { BrowserRouter, Route } from "react-router-dom";
var isletmeler = require("../../src/isletmeler.json");

export default class App extends React.Component {
  render() {
    const isletmelerinHepsi = isletmeler.map((isletme) => (
      <Route
        path={"/" + isletme.url}
        component={() => (
          <GirisPage
            isletmeAdi={isletme.adi}
            isletmeURL={isletme.url}
            staticIp={isletme.staticIp}
            uyelikDurumu={isletme.uyelikDurumu}
          />
        )}
      />
    ));
    const isletmelerinHepsiOnlineİslemler = isletmeler.map((isletme) => (
      <Route
        path={"/" + isletme.url + "_onlineislemler"}
        component={() => (
          <OnlineIslemler
            isletmeAdi={isletme.adi}
            isletmeURL={isletme.url}
            staticIp={isletme.staticIp}
            sanalPosUrl={isletme.sanalPosUrl}
          />
        )}
      />
    ));
    return (
      <BrowserRouter>
        <div>
          <Route exact path="/" component={() => <HomePage />} />
          {isletmelerinHepsi}
          {isletmelerinHepsiOnlineİslemler}
        </div>
      </BrowserRouter>
    );
  }
}
